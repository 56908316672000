/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import get from "lodash/get"

import Quote from "./design-partner/quote"

const TestimonialsModule = ({ bladePatternData }) => {
  if (!get(bladePatternData, "contentCards.length")) return
  return (
    <div
      id={bladePatternData.anchorLink}
      sx={{
        py: theme => [theme.spacing.vertical.md],
      }}
    >
      {bladePatternData.contentCards.map((basicCard, idx) =>
        get(basicCard, "description.json") ? (
          <Quote
            key={idx}
            quote={{ quote: { json: basicCard.description.json } }}
            photo={basicCard.desktopImage}
            name={basicCard.title}
          />
        ) : null
      )}
    </div>
  )
}

TestimonialsModule.propTypes = {
  bladePatternData: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    ctaTitle: PropTypes.string,
    ctaDestination: PropTypes.string,
    description: PropTypes.object,
    desktopImage: PropTypes.object,
    mobileImages: PropTypes.object,
    contentCards: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        ctaTitle: PropTypes.string,
        ctaDestination: PropTypes.object,
        description: PropTypes.object,
        desktopImage: PropTypes.object,
        mobileImages: PropTypes.object,
      })
    ),
  }),
}

export default TestimonialsModule
