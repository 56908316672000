/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { findBySlug } from "../helpers"
import get from "lodash/get"

import CTAsModule from "../components/ctas-module"
import Hero from "../components/hero"
import Page from "../components/page"
import ProjectUnique from "../components/project-unique"
import BenefitsModule from "../components/benefits-module"
import ProjectGalleryModule from "../components/project-gallery-module"
import AdvantagesModule from "../components/advantages-module"
import TestimonialsModule from "../components/testimonials-module"
import ProcessModule from "../components/process-module"
import Quote from "../components/design-partner/quote"

import { paramsCheck } from "../functions/functions"
import { object } from "prop-types"
import { useEffect } from "react"

const ModularHomes = ({ data, location }) => {
  paramsCheck()

  let hashVal = location.hash.split("?")

  useEffect(() => {
    if (location.hash && hashVal[0] && typeof window !== null) {
      let id = hashVal[0].substring(1)
      const elem = document.getElementById(id)
      if (elem) {
        window.scrollTo({ left: 0, top: elem.offsetTop + 30 })
      }
    }
  }, [])

  const renderHeroModule = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "modular-homes-hero-module",
    })
    const heroHeights = ["auto", 555]
    if (!heroBladePatternData) return

    const ctaList = heroBladePatternData.contentCards.map(basicCard => ({
      text: basicCard.ctaTitle,
      type: "secondary",
      link: basicCard.ctaDestination
        ? basicCard.ctaDestination.ctaDestination
        : "/",
    }))

    return (
      <Hero
        customStyle={{
          minHeight: heroHeights,
          height: heroHeights,
        }}
        background={{
          type: "image",
          source: {
            mobileImage: heroBladePatternData.mobileImage,
            desktopImage: heroBladePatternData.desktopImage,
          },
        }}
        title={heroBladePatternData.title}
        body={get(heroBladePatternData, "description.description")}
        ctaList={ctaList}
        type="secondary"
      />
    )
  }
  const renderBenefitsModule = () => {
    const benefitsModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "modular-homes-benefits-module",
    })

    if (!benefitsModuleBladePatternData) return

    return <BenefitsModule bladePatternData={benefitsModuleBladePatternData} />
  }
  const renderServicesModule = () => {
    const servicesModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "homebuyers-services-module",
    })

    if (!servicesModuleBladePatternData) return

    return <ProjectUnique data={servicesModuleBladePatternData} />
  }
  
  const renderProcessModule = () => {
    let processTextModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "homebuyers-process-module",
    })
    let checkContent  = processTextModuleBladePatternData.contentCards.filter(item => item.desktopImage === null)
    
    if (!processTextModuleBladePatternData || checkContent.length > 0) return
    return (
      <ProcessModule bladePatternData={processTextModuleBladePatternData} />
    )
  }

  const renderProcessTextModule = () => {
    let processTextModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "homebuyers-process-text-module",
    })
    if (!processTextModuleBladePatternData) return

    return (
      <ProcessModule bladePatternData={processTextModuleBladePatternData} />
    )
  }
  const renderCtaModule = () => {
    const ctaModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "homebuyers-cta-module",
    })

    if (!ctaModuleBladePatternData) return

    return (
      <CTAsModule
        textStyle={{ color: theme => theme.colors.white }}
        bladePatternData={ctaModuleBladePatternData}
      />
    )
  }
  const renderProjectGalleryModule = () => {
    const projectGalleryModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "homebuyers-gallery-module",
    })

    if (!projectGalleryModuleBladePatternData) return

    return (
      <ProjectGalleryModule
        bladePatternData={projectGalleryModuleBladePatternData}
        // projectsData={data.allContentfulProject}
        customStyle={{ pb: "61%", height: 0 }}
      />
    )
  }

  

  return (
    <Page data={data.contentfulPage}>
      {renderHeroModule()}
      {renderBenefitsModule()}
      {renderServicesModule()}
      {renderProcessModule()}
      {renderProcessTextModule()}
      {renderProjectGalleryModule()}
      {renderCtaModule()}
    </Page>
  )
}

export default ModularHomes

export const query = graphql`
  query modularhomesPageQuery {
    contentfulPage(slug: { eq: "modular-homes" }) {
      ...PageData
      ...BladePatternData
    }

    allContentfulProject(limit: 3, sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          projectImages {
            id
            title
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          projectLocation {
            projectLocation
          }
          projectTitle {
            projectTitle
          }
          slug
          projectTytpe
        }
      }
    }
  }
`
