/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion"

import Text from "./text"
import RichText from "./rich-text"
import chevron from "../images/icon-arrow.svg"

const BladePatternAccordeon = ({ data }) => {
  return (
    <Accordion allowZeroExpanded={true}>
      {data.contentCards.map((basicCard, idx) => (
        <AccordionItem
          sx={{
            borderTop: "1px solid #ccc",
            ":last-of-type": {
              borderBottom: "1px solid #ccc",
            },
          }}
          key={idx}
        >
          <AccordionItemHeading>
            <AccordionItemButton
              sx={{
                py: "15px",
              }}
            >
              <Flex
                sx={{
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <AccordionItemState>
                  {state => (
                    <span
                      sx={{
                        position: "absolute",
                        top: "2px",
                        left: "0px",
                      }}
                    >
                      <img
                        src={chevron}
                        sx={{
                          transform: state?.expanded
                            ? "rotate(90deg)"
                            : "rotate(-90deg)",
                        }}
                        width="18"
                        height="36"
                      />
                    </span>
                  )}
                </AccordionItemState>
                <Text type="h3" customStyle={{ pl: 30 }}>
                  {basicCard.title}
                </Text>
                {/* TODO: find a way to handle images for improve the use of this component */}
                {basicCard.mobileImage ? (
                  <img
                    src={basicCard.mobileImage.file.url}
                    alt=""
                    sx={{ ml: "auto", height: 40 }}
                  />
                ) : null}
              </Flex>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div
              sx={{
                px: 30,
                h4: {
                  ":first-child": {
                    pt: 2,
                  },
                  pt: 4,
                  mb: 2,
                  fontSize: 18,
                  fontWeight: 500,
                  fontFamily: "heading",
                  color: "text",
                },
                "p:last-child": {
                  mb: 4,
                },
                p: {
                  mt: 2,
                },
              }}
            >
              <RichText data={basicCard.description} />
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

export default BladePatternAccordeon
