/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import { Component } from "react"
import cloneDeep from "lodash/cloneDeep"
import {
  Tab as ReachTab,
  TabList as ReachTabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@reach/tabs"

import Column from "./column"
import Row from "./row"
import RichText from "./rich-text"
import Text from "./text"

const styles = {
  tabStyle: {
    background: "none",
    border: "none",
    cursor: "pointer",
    display: "inline-block",
    mr: 50,
    p: 0,
    position: "relative",
    whiteSpace: ["nowrap", "nowrap", "normal"],
  },
  tabStyleStep: {
    "::before": {
      content: "''",
      display: "block",
      position: "absolute",
      width: "200%",
      height: "2px",
      left: 0,
      top: 0,
      bottom: 0,
      my: "auto",
      background: theme => theme.colors.primary,
    },
  },
  stick: {
    "::before": {
      content: "''",
      display: "block",
      position: "absolute",
      width: "200%",
      height: "2px",
      left: 0,
      top: 0,
      bottom: 0,
      my: "auto",
      background: "black",
    },
  },
  tabListStyle: {
    py: theme => theme.spacing.vertical.lg,
  },
  buttonStyle: {
    position: "absolute",
    top: 0,
    bottom: 0,
    my: "auto",
    height: 40,
    background: "none",
    border: "none",
    cursor: "pointer",
    display: "inline-block",
    fontSize: 28,
    color: theme => theme.colors.primary,
  },
}
class BladePatternTabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0,
    }
  }

  renderTabPanels() {
    const { contentCards } = this.props.data

    return contentCards.map((basicCard, idx) => {
      let columns = []
      let currentColumn = []
      let close = false
      const createColumn = () => {
        let tmpColumn = cloneDeep(basicCard.description.json)
        tmpColumn.content = cloneDeep(currentColumn)
        columns.push(tmpColumn)
        currentColumn = []
      }
      // We go through the whole rich text object content and split it in columns by the nodeType
      basicCard.description.json.content.forEach((node, idx) => {
        if (node.nodeType === this.props.splitNodeType) {
          if (close) {
            createColumn()
          }
          close = true
        }
        currentColumn.push(node)
        if (basicCard.description.json.content.length - 1 === idx) {
          createColumn()
        }
      })

      return (
        <TabPanel key={idx}>
          <Row>
            {columns.map((column, idx) => (
              <Column
                customStyle={{ px: 25 }}
                size={[12, 12, columns.length === 4 ? 3 : 4]}
                key={idx}
              >
                <RichText data={{ json: column }} />
              </Column>
            ))}
          </Row>
        </TabPanel>
      )
    })
  }
  onNextClick() {
    const maxTabs = this.props.data.contentCards.length - 1
    this.setState(
      state => (state.currentIndex += state.currentIndex >= maxTabs ? 0 : 1)
    )
  }
  onPreviousClick() {
    this.setState(
      state => (state.currentIndex -= state.currentIndex === 0 ? 0 : 1)
    )
  }
  handleTabsChange(index) {
    this.setState(state => (state.currentIndex = index))
  }
  render() {
    const { contentCards } = this.props.data
    const { currentIndex } = this.state
    const { onNextClick, onPreviousClick, handleTabsChange } = this

    return (
      <Tabs index={currentIndex} onChange={handleTabsChange.bind(this)}>
        <div sx={{ display: "inline-block", position: "relative" }}>
          <button
            sx={{ ...styles.buttonStyle, left: -50 }}
            onClick={onPreviousClick.bind(this)}
          >
            &lt;
          </button>
          <button
            sx={{ ...styles.buttonStyle, right: 10 }}
            onClick={onNextClick.bind(this)}
          >
            &gt;
          </button>
          <ReachTabList sx={styles.tabListStyle}>
            {contentCards.map((basicCard, idx) => (
              <ReachTab
                sx={{
                  ...styles.tabStyle,
                  ...(idx !== contentCards.length - 1 ? styles.stick : {}),
                  ...(idx < currentIndex ? styles.tabStyleStep : {}),
                }}
                key={idx}
              >
                <Flex
                  sx={{
                    flexDirection: "column",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  {basicCard.desktopImage ? (
                    <div
                      sx={{
                        display: "block",
                        width: [70, 70, 85, 100],
                        height: [70, 70, 85, 100],
                        backgroundColor: "white",
                        backgroundImage: `url(${basicCard.desktopImage.file.url})`,
                        backgroundPosition: "50% 50%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "50%",
                        borderRadius: "50%",
                        border: theme =>
                          `1px solid ${
                            idx <= currentIndex ? theme.colors.primary : "black"
                          }`,
                        mb: 10,
                      }}
                    ></div>
                  ) : null}
                  <Text
                    customStyle={{
                      position: "absolute",
                      bottom: -70,
                      color: theme =>
                        idx <= currentIndex ? theme.colors.primary : "black",
                    }}
                  >
                    {basicCard.title}
                  </Text>
                </Flex>
              </ReachTab>
            ))}
          </ReachTabList>
        </div>
        <TabPanels>{this.renderTabPanels()}</TabPanels>
      </Tabs>
    )
  }
}

BladePatternTabs.defaultProps = {
  splitNodeType: "heading-4",
}

export default BladePatternTabs
