/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { renderLineBreak } from "../helpers"

import Container from "./container"
import Column from "./column"
import CTA from "./cta"
import RichText from "./rich-text"
import Row from "./row"
import Text from "./text"

const CTAsModule = ({
  bladePatternData,
  textStyle,
  customStyle,
  ctaOnClick,
}) => {
  return (
    <div
      id={bladePatternData.anchorLink}
      sx={{
        py: theme => [
          theme.spacing.vertical.md,
          theme.spacing.vertical.md,
          theme.spacing.vertical.lg,
        ],
        backgroundColor: theme => theme.colors.accent,
      }}
    >
      <Container customStyle={{ ...customStyle }}>
        <Row>
          <Column>
            <Text
              type="h2"
              customStyle={{ ...textStyle, maxWidth: [null, null, "40%"] }}
            >
              {renderLineBreak(bladePatternData.title)}
            </Text>

            {bladePatternData.contentCards &&
            bladePatternData.contentCards[0] &&
            bladePatternData.contentCards[0].description ? (
              <div
                sx={{
                  mt: 2,
                }}
              >
                <RichText
                  textStyle={{
                    color: "white",
                    mb: 1,
                  }}
                  data={bladePatternData.contentCards[0].description}
                />
              </div>
            ) : null}
            <div
              sx={{
                mt: 3,
              }}
            >
              {bladePatternData.contentCards &&
                bladePatternData.contentCards.map((basicCard, idx) =>
                  basicCard.ctaTitle ? (
                    ctaOnClick ? (
                      <CTA
                        link={
                          basicCard.ctaDestination
                            ? basicCard.ctaDestination.ctaDestination
                            : ""
                        }
                        key={idx}
                        type={idx === 0 ? "primary" : "alt"}
                        linkType="internal"
                        customStyle={{
                          mr: theme => theme.spacing.horizontal,
                          fontSize: 3,
                          border: 0,
                          ...(idx !== 0 && { pr: "40px" }),
                        }}
                        {...{
                          onClick: e =>
                            ctaOnClick(e, idx, {
                              to:
                                (basicCard.ctaDestination &&
                                  basicCard.ctaDestination.ctaDestination) ||
                                (basicCard.ctaDestinationExternal &&
                                  basicCard.ctaDestinationExternal
                                    .ctaDestinationExternal) ||
                                "/",
                              type: basicCard.ctaDestinationExternal
                                ? "external"
                                : "internal",
                            }),
                        }}
                      >
                        {basicCard.ctaTitle}
                      </CTA>
                    ) : (
                      <CTA
                        link={
                          basicCard.ctaDestination
                            ? basicCard.ctaDestination.ctaDestination
                            : ""
                        }
                        key={idx}
                        type={idx === 0 ? "primary" : "alt"}
                        linkType="internal"
                        customStyle={{
                          mr: theme => theme.spacing.horizontal,
                          fontSize: 3,
                          border: 0,
                          ...(idx !== 0 && { pr: "40px" }),
                        }}
                      >
                        {basicCard.ctaTitle}
                      </CTA>
                    )
                  ) : null
                )}
            </div>
          </Column>
        </Row>
      </Container>
    </div>
  )
}

CTAsModule.defaultProps = {
  ctaType: "secondary",
}

CTAsModule.propTypes = {
  bladePatternData: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    ctaTitle: PropTypes.string,
    ctaDestination: PropTypes.string,
    description: PropTypes.object,
    desktopImage: PropTypes.object,
    mobileImages: PropTypes.object,
    contentCards: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        ctaTitle: PropTypes.string,
        ctaDestination: PropTypes.object,
        description: PropTypes.object,
        desktopImage: PropTypes.object,
        mobileImages: PropTypes.object,
      })
    ),
  }),
}

export default CTAsModule
