/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import get from "lodash/get"
import { renderLineBreak } from "../helpers"

import { useMobileImageSelector } from "../hooks/use-mobile-image-selector"

import Column from "../components/column"
import Container from "../components/container"
import CTA from "../components/cta"
import ProjectCard from "../components/project-card"
import Row from "../components/row"
import Text from "../components/text"

const AdvantagesModule = ({ bladePatternData }) => {
  const mobileImageSelector = useMobileImageSelector()
  if (!bladePatternData.contentCards) return
  return (
    <div
      id={bladePatternData.anchorLink}
      sx={{
        pt: theme => [
          theme.spacing.vertical.md,
          theme.spacing.vertical.md,
          theme.spacing.vertical.lg,
        ],
        backgroundColor: theme => [
          theme.colors.backgrounds.primary,
          theme.colors.white,
        ],
      }}
    >
      <Container>
        <Row>
          {bladePatternData.contentCards.map((basicCard, idx) => {
            return (
              <Column
                customStyle={{
                  mb: theme => ["8px", "8px", 0],
                  ":last-child": { mb: 0 },
                  position: "relative",
                }}
                key={idx}
                size={[12, 12, null]}
              >
                <ProjectCard
                  background={mobileImageSelector(
                    get(basicCard, "desktopImage.file.url"),
                    get(basicCard, "mobileImage.file.url")
                  )}
                  text={[basicCard.title]}
                  sx={{ height: 0, pb: ["50%", "50%", "160%"] }}
                />
              </Column>
            )
          })}
        </Row>
        <Row
          customStyle={{
            py: theme => [
              theme.spacing.vertical.md,
              theme.spacing.vertical.md,
              theme.spacing.vertical.lg,
            ],
          }}
        >
          <Column size={[12, 12, 5]}>
            <Text type="h2">{renderLineBreak(bladePatternData.title)}</Text>
          </Column>
          <Column size={[12, 12, 4]}>
            <Text
              type="p"
              customStyle={{
                mt: theme => [
                  theme.spacing.vertical.sm,
                  theme.spacing.vertical.sm,
                  0,
                ],
                mb: theme => theme.spacing.vertical.horizontal,
              }}
            >
              {bladePatternData.description.description}
            </Text>
            {bladePatternData.ctaTitle ? (
              <div sx={{ mt: 20 }}>
                <CTA
                  link={bladePatternData.ctaDestination || "/"}
                  type="secondary"
                  linkType="internal"
                  customStyle={{
                    maxWidth: 165,
                  }}
                >
                  {bladePatternData.ctaTitle}
                </CTA>
              </div>
            ) : null}
          </Column>
        </Row>
      </Container>
    </div>
  )
}

AdvantagesModule.propTypes = {
  bladePatternData: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    ctaTitle: PropTypes.string,
    ctaDestination: PropTypes.string,
    description: PropTypes.object,
    desktopImage: PropTypes.object,
    mobileImage: PropTypes.object,
    contentCards: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        ctaTitle: PropTypes.string,
        ctaDestination: PropTypes.object,
        description: PropTypes.object,
        desktopImage: PropTypes.object,
        mobileImage: PropTypes.object,
      })
    ),
  }),
}
export default AdvantagesModule
